export const colors = {
  brand: {
    50: '#E6F7FF', // Updated from #EBF8FF
    100: '#BAE7FF', // Updated from #BEE3F8
    200: '#91D5FF', // Updated from #90CDF4
    300: '#69C0FF', // Updated from #63B3ED
    400: '#40A9FF', // Updated from #4299E1
    500: '#1890FF', // Updated from #3182CE
    600: '#096DD9', // Updated from #2B6CB0
    700: '#0050B3', // Updated from #2C5282
    800: '#003A8C', // Updated from #2A4365
    900: '#002766', // Updated from #1A365D
  },
  gradients: {
    primary: 'linear-gradient(135deg, #1E40AF 0%, #3B82F6 50%, #60A5FA 100%)',
    hero: 'linear-gradient(135deg, #1E3A8A 0%, #2563EB 50%, #60A5FA 100%)',
    card: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06))',
    background: 'linear-gradient(135deg, #1E3A8A 0%, #2563EB 50%, #3B82F6 100%)',
    glass: 'linear-gradient(135deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.06))',
  },
};
